import client from "./client";
export const addComment = async (id, stageNumber, obj) => {
    try {
      const { data } = await client.post(
        `/collage/addComment/${id}/${stageNumber}`,
        obj
      );
      return data;
    } catch (error) {
      const { response } = error;
      if (response?.data) {
        return response.data;
      }
      return { error: error.message || error };
    }
  };