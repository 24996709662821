import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";

import { Spin } from "antd";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import { acc_agency_signin } from "../api/auth";
import { encryptRole } from "../helper/EncryptionUtils";
import { decryptRole } from "./EncryptionUtils";

function LoginPage() {
  const initialValues = {
    email: "",
    password: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = await acc_agency_signin(formValues);
      if (data?.success) {
        toast.success(`Login Successfully`);
        console.log(data);
        localStorage.setItem("id", data.id);
        localStorage.setItem("role", encryptRole(data.role));
        localStorage.setItem("authToken", data.token);
        let role = localStorage.getItem("role");
        if (decryptRole(role) === "Accounter") navigate("/card");
        else if (decryptRole(role) === "agency") {
          navigate("/agencyStudentTable");
        }

        // navigate("/studentTable");
        setLoading(false);
      } else {
        toast.error("Invalid Password or Email");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <Toaster position="top-center" reverseOrder={false} />

      <form
        onSubmit={handleSubmit}
        className="max-w-md w-4/5 mx-auto p-8 rounded-md shadow-lg bg-white bg-opacity-50 border"
      >
        <h1 className="text-2xl font-bold mb-4 text-center">LOGIN</h1>
        <div className="mb-4">
          <label className="block text-black font-semibold">Email</label>
          <input
            type="text"
            name="email"
            placeholder="Email"
            value={formValues.email}
            onChange={handleChange}
            className="w-full p-2 rounded-sm border"
          />
          <p className="text-red-500">{formErrors.email}</p>
        </div>
        <div className="mb-4">
          <label className="blocktext-black font-semibold">Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={formValues.password}
              onChange={handleChange}
              className="w-full p-2 rounded-sm border"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-2 top-2"
            >
              {showPassword ? (
                <FaEyeSlash className="h-5 w-5 text-black" />
              ) : (
                <FaEye className="h-5 w-5 text-black" />
              )}
            </button>
          </div>
          <p className="text-red-500">{formErrors.password}</p>
        </div>

        <button className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:scale-105 transition duration-150">
          {loading ? (
            <div className="flex justify-center  items-center">
              <Spin
                size="large"
                spinning={loading}
                style={{ color: "white" }}
              />
            </div>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
}

export default LoginPage;
