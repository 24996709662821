import React, { useEffect, useState, useContext } from "react";
import CountUp from "react-countup";
import { useNavigate } from "react-router-dom";
import { StudentContext } from "../../context/StudentContext";
import { getBdms, getStudentFeeDetails } from "../../api/accounter";

const image1 = require("../../assets/1.png");
const image2 = require("../../assets/2.png");
const image3 = require("../../assets/3.png");
const image4 = require("../../assets/4.png");
const image9 = require("../../assets/9.png");
const image11 = require("../../assets/11.png");

const Card = () => {
  const [applicationFeePending, setApplicationFeePending] = useState(0);
  const [applicationFeeDone, setApplicationFeeDone] = useState(0);
  const [tuitionFeePending, setTuitionFeePending] = useState(0);
  const [tuitionFeeDone, setTuitionFeeDone] = useState(0);
  const [refundDone, setRefundDone] = useState(0);
  const [refundPending, setRefundPending] = useState(0);
  const [all, setAll] = useState(0);

  const studentContext = useContext(StudentContext);
  const { students, getstudents } = studentContext;
  let navigate = useNavigate();

  function calculateBdmWiseTotals(data) {
    let bdmWiseTotals = {};

    let othersRecords = {
      bdm: "OTHERS",
      totalApplicationFee: 0,
      applicationStudent: 0,
      applicationIncentive: 0,

      totalTutionFee: 0,
      tutionStudent: 0,
      tutionIncentive: 0,

      totalFees: 0,
    };

    data.forEach((entry) => {
      let bdm1 = entry.bdm1;
      let bdm2 = entry.bdm2;
      if (!bdm1 || !bdm2) {
        othersRecords.totalApplicationFee += parseInt(
          entry.Application_Fee_Paid
        );
        othersRecords.totalTutionFee += parseInt(entry.Tution_Fee_Paid);
        othersRecords.totalFees +=
          parseInt(entry.Application_Fee_Paid) +
          parseInt(entry.Tution_Fee_Paid);

        if (entry.Application_Fee_Paid >= 200) {
          othersRecords.applicationStudent += 1;
          othersRecords.applicationIncentive += 5000;
        }
        if (entry.totalFeeRemaining <= entry.totalTutionFee * 0.5) {
          othersRecords.tutionStudent += 1;
          othersRecords.tutionIncentive += 10000;
        }
      }
      if (bdm1 && bdm2) {
        const totalApplicationFee = parseInt(entry.Application_Fee_Paid);
        const totalTutionFee = parseInt(entry.Tution_Fee_Paid);
        const totalFees = totalApplicationFee + totalTutionFee;

        if (bdm1 === bdm2) {
          if (!bdmWiseTotals[bdm1]) {
            bdmWiseTotals[bdm1] = {
              totalApplicationFee: 0,
              totalTutionFee: 0,
              totalFees: 0,
              applicationStudent: 0,
              applicationIncentive: 0,
              tutionStudent: 0,
              tutionIncentive: 0,
            };
          }
          bdmWiseTotals[bdm1].totalApplicationFee += totalApplicationFee;
          bdmWiseTotals[bdm1].totalTutionFee += totalTutionFee;
          bdmWiseTotals[bdm1].totalFees += totalFees;

          if (entry.Application_Fee_Paid >= 200) {
            bdmWiseTotals[bdm1].applicationStudent += 1;
            bdmWiseTotals[bdm1].applicationIncentive += 5000;
          }
          if (entry.totalFeeRemaining <= entry.totalTutionFee * 0.5) {
            bdmWiseTotals[bdm1].tutionStudent += 1;
            bdmWiseTotals[bdm1].tutionIncentive += 10000;
          }
        } else {
          if (!bdmWiseTotals[bdm1]) {
            bdmWiseTotals[bdm1] = {
              totalApplicationFee: 0,
              totalTutionFee: 0,
              totalFees: 0,
              applicationStudent: 0,
              applicationIncentive: 0,
              tutionStudent: 0,
              tutionIncentive: 0,
            };
          }
          if (!bdmWiseTotals[bdm2]) {
            bdmWiseTotals[bdm2] = {
              totalApplicationFee: 0,
              totalTutionFee: 0,
              totalFees: 0,
              applicationStudent: 0,
              applicationIncentive: 0,
              tutionStudent: 0,
              tutionIncentive: 0,
            };
          }

          const halftotalApplicationFeeTotalFees = totalApplicationFee / 2;
          const halftotalTutionFeeTotalFees = totalTutionFee / 2;

          const totalHalfFeessum =
            halftotalApplicationFeeTotalFees + halftotalTutionFeeTotalFees;
          bdmWiseTotals[bdm1].totalApplicationFee +=
            halftotalApplicationFeeTotalFees;
          bdmWiseTotals[bdm1].totalTutionFee += halftotalTutionFeeTotalFees;
          bdmWiseTotals[bdm1].totalFees += totalHalfFeessum;

          if (entry.Application_Fee_Paid >= 200) {
            bdmWiseTotals[bdm1].applicationStudent += 1;
            // bdmWiseTotals[bdm1].applicationIncentive += 5000;
            bdmWiseTotals[bdm1].applicationIncentive += 2500;
          }
          if (entry.totalFeeRemaining <= entry.totalTutionFee * 0.5) {
            bdmWiseTotals[bdm1].tutionStudent += 1;
            //bdmWiseTotals[bdm1].tutionIncentive += 10000;
            bdmWiseTotals[bdm1].tutionIncentive += 5000;
          }

          bdmWiseTotals[bdm2].totalApplicationFee +=
            halftotalApplicationFeeTotalFees;
          bdmWiseTotals[bdm2].totalTutionFee += halftotalTutionFeeTotalFees;
          bdmWiseTotals[bdm2].totalFees += totalHalfFeessum;

          if (entry.Application_Fee_Paid >= 200) {
            bdmWiseTotals[bdm2].applicationStudent += 1;
            //bdmWiseTotals[bdm2].applicationIncentive += 5000;
            bdmWiseTotals[bdm2].applicationIncentive += 2500;
          }
          if (entry.totalFeeRemaining <= entry.totalTutionFee * 0.5) {
            bdmWiseTotals[bdm2].tutionStudent += 1;
            // bdmWiseTotals[bdm2].tutionIncentive += 10000;
            bdmWiseTotals[bdm2].tutionIncentive += 5000;
          }
        }
      }
    });
    let bdmWiseTotalsArray = Object.keys(bdmWiseTotals).map((bdm) => {
      return {
        bdm,
        ...bdmWiseTotals[bdm],
      };
    });
    bdmWiseTotalsArray.push(othersRecords);
    return bdmWiseTotalsArray;
  }

  const fetchDataFromBackend = async () => {
    const bdmInfos = await getBdms();
    const feeInfo = await getStudentFeeDetails();
    let updatedStudents = [];
    students.map((student) => {
      updatedStudents.push({
        ...student,
        ...feeInfo.result[student.enrollmentno.substring(3)],
        bdm1: bdmInfos.result[student.enrollmentno.substring(3)]?.BDM1,
        bdm2: bdmInfos.result[student.enrollmentno.substring(3)]?.BDM2,
        clientType:
          bdmInfos.result[student.enrollmentno.substring(3)]?.clientType,
      });
    });
    calculateBdmWiseTotals(updatedStudents);
  };

  // tuitionFeesStatus as tuitionFeesStatus , paymentStatus as Application Fee status

  useEffect(() => {
    fetchDataFromBackend();
  }, [students]);
  useEffect(() => {
    getstudents();
  }, []);

  useEffect(() => {
    // Calculate counts
    const appFeePending = students.filter(
      (student) => student.paymentStatus === "Sent for approval"
    ).length;
    const appFeeDone = students.filter(
      (student) => student.paymentStatus === "Done"
    ).length;
    const tuitionFeePending = students.filter(
      (student) => student.tuitionFeesStatus === "Sent for approval"
    ).length;
    const tuitionFeeDone = students.filter(
      (student) => student.tuitionFeesStatus === "Done"
    ).length;
    const refundPending = students.filter(
      (student) => student.refundStatus === "Send For Approval"
    ).length;
    const refundDone = students.filter(
      (student) => student.refundStatus === "Approved"
    ).length;

    setAll(students.length);
    setApplicationFeePending(appFeePending);
    setApplicationFeeDone(appFeeDone);
    setTuitionFeePending(tuitionFeePending);
    setTuitionFeeDone(tuitionFeeDone);
    setRefundDone(refundDone);
    setRefundPending(refundPending);
  }, [students]);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-24 p-10">
        <div
          className="bg-[#FFEDD5] flex flex-row space-y-3 justify-between items-center
         rounded-2xl  p-6 hover:scale-105 transition-all 
         duration-100 mx-auto ease-in-out cursor-pointer w-100 h-70  shadow"
          onClick={() => {
            navigate(`/studentTable/${1}`);
          }}
        >
          <p className="text-3xl text-center w-fit px-4 py-1 rounded-full bg-orange-100 font-bold text-orange-500">
            <span className="counter-value">
              <CountUp start={0} end={applicationFeePending} duration={2} />
            </span>
            <p className="text-2xl  font-semibold text-orange-500">
              Application Fee Pending
            </p>
          </p>
          <div className=" flex justify-center w-full items-center pb-4 mb-4">
            <img src={image2} alt="Image" className="w-20 h-20 ml-3" />
          </div>
        </div>

        <div
          className="bg-[#ebf5dc] flex flex-row space-y-3 justify-between 
        items-center rounded-2xl p-6 hover:scale-105 transition-all
         duration-100 mx-auto ease-in-out cursor-pointer w-100 h-70   shadow"
          onClick={() => {
            navigate(`/studentTable/${2}`);
          }}
        >
          <p
            className="text-3xl text-center w-fit px-4 py-1 rounded-full
        font-bold  text-lime-500"
          >
            <span className="counter-value text-lime-500">
              <CountUp start={0} end={tuitionFeePending} duration={2} />
            </span>
            <p className="text-2xl  font-semibold">Tution Fee Pending</p>
          </p>
          <div className=" flex justify-center w-full items-center pb-4 mb-4">
            <img src={image3} alt="Image" className="w-20 h-20 ml-3" />
          </div>
        </div>

        <div
          className="bg-[#ebf5dc] flex flex-row space-y-3 justify-between 
                  items-center rounded-2xl p-6 hover:scale-105 transition-all
                   duration-100 mx-auto ease-in-out cursor-pointer w-100 h-70   shadow"
          onClick={() => {
            navigate(`/studentTable/${3}`);
          }}
        >
          <p className="text-3xl text-center w-fit px-4 py-1 rounded-full  font-bold text-pink-500">
            <span className="counter-value">
              <CountUp start={0} end={applicationFeeDone} duration={2} />
            </span>
            <p className="text-2xl  font-semibold text-pink-500">
              Application Fee Done
            </p>
          </p>
          <div className=" flex justify-center w-full items-center pb-4 mb-4">
            <img src={image4} alt="Image" className="w-20 h-20 ml-3" />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-5">
        <div
          className="bg-[#ccf6ff] flex flex-row space-y-3 justify-between items-center 
        rounded-2xl  p-6 hover:scale-105 transition-all duration-100 ease-in-out mx-auto cursor-pointer w-100 h-70 shadow"
          onClick={() => {
            navigate(`/studentTable/${4}`);
          }}
        >
          <p className="text-3xl text-center w-fit px-4 py-1 rounded-full font-semibold text-[#5597a6]">
            <span className="counter-value">
              <CountUp start={0} end={tuitionFeeDone} duration={2} />
            </span>
            <p className="text-2xl font-semibold text-[#5597a6]">
              Tution Fee Done
            </p>
          </p>
          <div className="flex justify-center w-full items-center pb-4 mb-4">
            <img src={image11} alt="Image" className="w-20 h-20 ml-3" />
          </div>
        </div>
        <div
          className="bg-[#ccf6ff] flex flex-row space-y-3 justify-between items-center 
        rounded-2xl  p-6 hover:scale-105 transition-all duration-100 ease-in-out mx-auto cursor-pointer w-100 h-70 shadow"
          onClick={() => {
            navigate(`/studentTable/${5}`);
          }}
        >
          <p className="text-3xl text-center w-fit px-4 py-1 rounded-full font-semibold text-[#5597a6]">
            <span className="counter-value">
              <CountUp start={0} end={refundPending} duration={2} />
            </span>
            <p className="text-2xl font-semibold text-[#5597a6]">
              Refund Pending
            </p>
          </p>
          <div className="flex justify-center w-full items-center pb-4 mb-4">
            <img src={image11} alt="Image" className="w-20 h-20 ml-3" />
          </div>
        </div>
        <div
          className="bg-[#ccf6ff] flex flex-row space-y-3 justify-between items-center 
        rounded-2xl  p-6 hover:scale-105 transition-all duration-100 ease-in-out mx-auto cursor-pointer w-100 h-70 shadow"
          onClick={() => {
            navigate(`/studentTable/${6}`);
          }}
        >
          <p className="text-3xl text-center w-fit px-4 py-1 rounded-full font-semibold text-[#5597a6]">
            <span className="counter-value">
              <CountUp start={0} end={refundDone} duration={2} />
            </span>
            <p className="text-2xl font-semibold text-[#5597a6]">Refund Done</p>
          </p>
          <div className="flex justify-center w-full items-center pb-4 mb-4">
            <img src={image11} alt="Image" className="w-20 h-20 ml-3" />
          </div>
        </div>

        <div
          className="bg-[#ebf5dc] flex flex-row space-y-3 justify-between 
                  items-center rounded-2xl p-6 hover:scale-105 transition-all
                   duration-100 mx-auto ease-in-out cursor-pointer w-100 h-70   shadow"
          onClick={() => {
            navigate(`/studentTable/${7}`);
          }}
        >
          <p className="text-3xl text-center w-fit px-4 py-1 rounded-full  font-bold text-pink-500">
            <span className="counter-value">
              <CountUp start={0} end={all} duration={2} />
            </span>
            <p className="text-2xl  font-semibold text-pink-500">
              All Students
            </p>
          </p>
          <div className=" flex justify-center w-full items-center pb-4 mb-4">
            <img src={image4} alt="Image" className="w-20 h-20 ml-3" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
