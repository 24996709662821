import React, { useState, useEffect, useContext } from "react";
import { Spin } from "antd";
import ApplicationPayment from "./ApplicationPaymentSection/ApplicationPayment";
import PaymentPage from "./PaymentSection/Paymentcompo";
import ApplicationPaymentCompo from "./ApplicationPaymentSection/Paymentcompo";
import StudentInformation from "./studentInfo/StudentInformation";
import { StudentContext } from "../../context/StudentContext";

const Navbar = ({ setIsOpen }) => {
  const studentContext = useContext(StudentContext);
  const { inEditStudent } = studentContext;

  console.log(inEditStudent.id, "hello");

  const [componentToShow, setComponentToShow] = useState(
    "StudentInformation"
  );

  //Iterate through payment info object { id, amount, transactionno, status } whose status is Done then checkAvailability is true true

  // useEffect(() => {
  //   setComponentToShow("StudentInformation");
  // }, []);


  // const [componentToShow, setComponentToShow] = useState(
  //   "StudentInformationComponent"
  // );

  //Iterate through payment info object { id, amount, transactionno, status } whose status is Done then checkAvailability is true true

  useEffect(() => {
    setComponentToShow("StudentInformation");
  }, [inEditStudent.id]);


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const delay = setTimeout(() => {
      if (isMounted) {
        setLoading(false);
      }
    }, 500);
    return () => {
      isMounted = false;
      clearTimeout(delay);
    };
  }, [loading]);

  return (
    <Spin spinning={loading} size="large">
      <div>
        <nav className="bg-white relative w-full z-20 top-0 start-0 border-b border-gray-200">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-center mx-auto">
            <div
              className="items-center justify-between w-full flex md:w-auto md:order-1"
              id="navbar-sticky"
            >
              <ul className="flex mt-4 font-medium justify-center mx-auto rounded-lg md:space-x-8 rtl:space-x-reverse flex-row md:mt-0 md:border-0 bg-white">
                <li>
                  <p
                    data-name="StudentInformation"
                    onClick={(e) =>
                      setComponentToShow(e.currentTarget.dataset.name)
                    }
                    className={`cursor-pointer block py-3 px-3 ${componentToShow === "StudentInformation"
                      ? " border-b border-blue-500 text-blue-500 "
                      : "text-slate-600"
                      } md:bg-transparent `}
                    aria-current="page"
                  >
                    StudentInformation
                  </p>
                </li>
                <li>
                  <p
                    data-name="ApplicationFeesComponent"
                    onClick={(e) =>
                      setComponentToShow(e.currentTarget.dataset.name)
                    }
                    className={`cursor-pointer block py-3 px-3 ${componentToShow === "ApplicationFeesComponent"
                      ? " border-b border-blue-500 text-blue-500 "
                      : "text-slate-600"
                      } md:bg-transparent `}
                    aria-current="page"
                  >
                    Application Fees
                  </p>
                </li>
                <li>
                  <p
                    data-name="PaymentComponent"
                    onClick={(e) =>
                      setComponentToShow(e.currentTarget.dataset.name)
                    }
                    className={`cursor-pointer block py-3 px-3 ${componentToShow === "PaymentComponent"
                      ? "border-b border-blue-500 text-blue-500"
                      : "text-slate-600"
                      } md:bg-transparent`}
                  >
                    Tution Fees
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {componentToShow === "StudentInformation" && (
          <StudentInformation />
          // <div>Application fess</div>
        )}
        {componentToShow === "ApplicationFeesComponent" && (
          <ApplicationPaymentCompo />
          // <div>Application fess</div>
        )}
        {componentToShow === "PaymentComponent" && <PaymentPage />}
      </div>
    </Spin>
  );
};

export default Navbar;
