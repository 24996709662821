import React, { useState, useRef, useEffect } from "react";
import { Button, Input, Space, Table, Modal, DatePicker, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import { SiDocker } from "react-icons/si";
import { CiSaveDown1 } from "react-icons/ci";

const ReportHeaders = [
  { key: "bdm", label: "BDM" },
  { label: "Application Fee", key: "totalApplicationFee" },
  { label: "Tution Fee", key: "totalTuitionFee" },
  { label: "Total Fee", key: "totalFees" },
];

const ReportTable = ({ data }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchValue("");
  };
  const handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    isButtonClick = false
  ) => {
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    const handleSearch = (
      selectedKeys,
      confirm,
      dataIndex,
      isButtonClick = false
    ) => {
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);

      if (isButtonClick) {
        confirm();
      }
    };
    if (isButtonClick) {
      confirm();
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            const searchText = e.target.value;
            setSelectedKeys(searchText ? [searchText] : []);
            if (!searchText) {
              handleReset(clearFilters);
            } else {
              handleSearch([searchText], confirm, dataIndex);
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Space>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
              setSearchText("");
              setSearchText("");
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Search
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text?.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "BDM",
      dataIndex: "bdm",
      key: "bdm",
      align: "center",
      width: 110,
      ...getColumnSearchProps("bdm"),
      className: "custom-header-class font-semibold  border border-slate-300",
    },
    {
      title: "Application Fee",
      dataIndex: "totalApplicationFee",
      key: "totalApplicationFee",
      align: "center",
      width: 110,
      className: "custom-header-class  font-semibold  border border-slate-300",
    },
    {
      title: "Incentive Application Student",
      dataIndex: "applicationStudent",
      key: "applicationStudent",
      align: "center",
      width: 110,
      className: "custom-header-class  font-semibold   border border-slate-300",
    },
    {
      title: "Application Incentive (₹)",
      dataIndex: "applicationIncentive",
      key: "applicationIncentive",
      align: "center",
      width: 110,
      render: (text, record) => <p>{record.applicationIncentive} ₹</p>,
      className: "custom-header-class  font-semibold   border border-slate-300",
    },
    {
      title: "Tution Fee",
      dataIndex: "totalTutionFee",
      key: "totalTutionFee",
      align: "center",
      width: 110,
      className: "custom-header-class  font-semibold  border border-slate-300",
    },
    {
      title: "Incentive Tution Student",
      dataIndex: "tutionStudent",
      key: "tutionStudent",
      align: "center",
      width: 110,
      className: "custom-header-class  font-semibold   border border-slate-300",
    },
    {
      title: "Tution Incentive (₹)",
      dataIndex: "tutionIncentive",
      key: "tutionIncentive",
      align: "center",
      width: 110,
      render: (text, record) => <p>{record.tutionIncentive} ₹</p>,
      className: "custom-header-class  font-semibold  border border-slate-300",
    },
    {
      title: "Total Fee",
      dataIndex: "totalFees",
      key: "totalFees",
      align: "center",
      width: 110,
      className: "custom-header-class  font-semibold   border border-slate-300",
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      className="w-full"
      scroll={{ x: true }}
    />
  );
};

const Report = ({ dataSource }) => {

  const downloadCSV = () => {
    document.getElementById("csv-link-report").click();
  };

  return (
    <div className="p-4 w-full">
      <button
        className="text-blue-500 text-2xl font-bold"
        onClick={() => downloadCSV()}
      >
        <CiSaveDown1 />
      </button>
      <CSVLink
        id="csv-link-report"
        data={dataSource}
        headers={ReportHeaders}
        filename={"bdmReport.csv"}
      />
      <ReportTable data={dataSource}></ReportTable>
    </div>
  );
};

export default Report;
