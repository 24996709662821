export const FeeDetailsHeader = [
  { key: "enrollmentno", label: "Enrollment No." },
  { key: "firstName", label: "First Name" },
  { key: "lastName", label: "Last Name" },
  { key: "email", label: "EMAIL ID" },
  { key: "phoneNumber", label: "Phone Number" },
  { key: "bdm1", label: "BDM1" },
  { key: "bdm2", label: "BDM2" },
  { key: "totalFeeToPay", label: "Total Fee" },
  { key: "Application_Fee_Paid", label: "Application Fee Paid" },
  { key: "totalTutionFee", label: "Total Tution Fees" },
  { key: "Tution_Fee_Paid", label: "Tution Fee Paid" },
  { key: "maxCreatedAt", label: "Last Date of Transaction Sent For Approval" },
  { key: "maxApprovedOn", label: "Last Approval Date" },
  // { key: "transactionDetails", label: "transactionDetails" },

];
