import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Required_component/Header";

import LoginPage from "./Authentication/Loginpage.jsx";
import StudentTable from "./Component/StudentTable.jsx/StudentTable.jsx";
import AgencyStudentTable from "./Component/StudentTable.jsx/AgencyStudentTable.jsx";
import Form from "./Component/Form_Components/Form.jsx";
import SetPassword from "./Authentication/SetPassword.jsx";
import Card from "./Component/Card/Card.jsx";
import { StudentProvider } from "./context/StudentContext.jsx";

function App() {
  return (
    <StudentProvider>
      <Router>
        <Header />
        <Routes initialRouteName="LoginPage">
          <Route path="/" element={<LoginPage />} />
          <Route path="/card" element={<Card />} />
          <Route path="/studentTable/:stageNo" element={<StudentTable />} />
          <Route path="/agencyStudentTable" element={<AgencyStudentTable />} />
          <Route path="/form" element={<Form />} />
          <Route path="/setPassword/:encryptedUrl" element={<SetPassword />} />
        </Routes>
      </Router>
    </StudentProvider>
  );
}

export default App;
