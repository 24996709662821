import client from "./client";

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
    credentials: "include",
  },
};


export const sendMail = async (obj) => {
  try {
    const { data } = await client.post("/student/sendMail", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};