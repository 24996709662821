import React, { useState, useRef, useEffect, useContext } from "react";
import "tailwindcss/tailwind.css";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Button, Input, Space, Table, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { StudentContext } from "../../context/StudentContext";
import { decryptRole } from "../../helper/EncryptionUtils";
import { allStudentsAgency } from "../../api/agency";

const DynamicTable = ({ data }) => {
  const studentContext = useContext(StudentContext);
  const { agencyStudents, setInEditStudent } = studentContext;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    // total: students.length,
    total: data.length,
    showQuickJumper: true,
  });
  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
  };
  const handleSearch = (
    selectedKeys,
    confirm,
    dataIndex,
    isButtonClick = false
  ) => {
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    const handleSearch = (
      selectedKeys,
      confirm,
      dataIndex,
      isButtonClick = false
    ) => {
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);

      if (isButtonClick) {
        confirm();
      }
    };
    if (isButtonClick) {
      confirm();
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            const searchText = e.target.value;
            setSelectedKeys(searchText ? [searchText] : []);
            if (!searchText) {
              handleReset(clearFilters);
            } else {
              handleSearch([searchText], confirm, dataIndex);
            }
          }}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />

        <Space>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
              setSearchText("");
              setSearchText("");
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Search
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Reference No",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
      align: "center",
      width: 110,
      ...getColumnSearchProps("referenceNumber"),
      render: (text, record, index) => {
        const highlightText = (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text?.toString() : ""}
          />
        );
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-bold -mb-2 text-center transition duration-300 ease-in-out transform hover:scale-105 text-blue-500 cursor-pointer">
              {searchedColumn === "referenceNumber"
                ? highlightText
                : text?.toString().toUpperCase()}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Student Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 110,
      ...getColumnSearchProps("name"),
      render: (text, record, index) => {
        const highlightText = (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text?.toString() : ""}
          />
        );
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center">
              {searchedColumn === "name" ? highlightText : text}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Email Id",
      dataIndex: "emailID",
      key: "emailID",
      align: "center",
      width: 90,
      ...getColumnSearchProps("emailID"),
      render: (text, record, index) => {
        const highlightText = (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text?.toString() : ""}
          />
        );
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center">
              {searchedColumn === "emailID" ? highlightText : text}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Contact No",
      dataIndex: "contactNumber",
      key: "contactNumber",
      align: "center",
      width: 90,
      ...getColumnSearchProps("contactNumber"),
      render: (text, record, index) => {
        const highlightText = (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text?.toString() : ""}
          />
        );
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center">
              {searchedColumn === "contactNumber" ? highlightText : text}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Intake",
      dataIndex: "intake",
      key: "intake",
      align: "center",
      width: 90,
      render: (text, record, index) => {
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center">
              {record?.intake}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Course",
      dataIndex: "course",
      key: "course",
      align: "center",
      width: 90,
      render: (text, record, index) => {
        return (
          <div className="bg-transparent rounded-lg -ml-2 -mr-2">
            <h2 className="text-sm font-semibold  text-center">
              {record?.course}
            </h2>
          </div>
        );
      },

      className: "custom-header-class  border border-slate-300",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 90,
      render: (_, record) => (
        <div className="flex text-center items-center ">
          <span className="font-semibold">{record?.status}</span>
        </div>
      ),
      className: "custom-header-class  border border-slate-300",
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={pagination}
      onChange={handleTableChange}
      className="w-full"
      scroll={{ x: true }}
    />
  );
};

function AgencyStudentTable() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  let aId = localStorage.getItem("id");
  const fetchDataFromBackend = async (aId) => {
    const data = await allStudentsAgency(aId);
    if (data) {
      setTableData(data);
    }
  };

  useEffect(() => {
    fetchDataFromBackend(aId);
  }, []);

  useEffect(() => {
    if (
      (localStorage.getItem("role") &&
        decryptRole(localStorage.getItem("role"))) !== "agency"
    ) {
      navigate("/");
    } else {
    }
  }, []);

  return (
    <div className="m-5">
      <Toaster />
      <div className="mx-auto p-4">
        {loading !== true ? (
          <DynamicTable data={tableData} />
        ) : (
          <>
            <Spin />
          </>
        )}
      </div>
    </div>
  );
}

export default AgencyStudentTable;
