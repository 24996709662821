import client from "./client";
// import CryptoJS from "crypto-js";
import { decryptRole } from "../Authentication/EncryptionUtils";
// import { toast } from "react-hot-toast";
const config = {
  headers: {
    "Content-Type": "multipart/form-data",
    credentials: "include",
  },
};

export const getPDF = async (obj) => {
  try {
    const { data } = await client.post("/student/get-pdf", obj);

    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const signPDF = async (obj) => {
  try {
    const { data } = await client.post("/student/generate-pdf", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
const getRole = () => {
  if (localStorage.getItem("role"))
    return decryptRole(localStorage.getItem("role"));
  else return null;
};
export const studentRegister = async (obj) => {
  try {
    if (getRole() && getRole() === "agency") {
      obj.append("role", getRole());
    }
    const { data } = await client.post("/student/register", obj, config);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
// obj = {email, role}
export const sendVerification = async (obj) => {
  try {
    const { data } = await client.post("/student/sendVerification", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const uploadDocument1 = async (formData, studentId) => {
  try {
    const { data } = await client.post(
      `/student/uploadDocument1/${studentId}`,
      formData
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const updateDocuments = async (obj, studentId) => {
  try {
    const { data } = await client.post(
      `/student/updateDocuments/${studentId}`,
      obj
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const uploadDocument2 = async (formData, studentId) => {
  try {
    const { data } = await client.post(
      `/student/uploadDocument2/${studentId}`,
      formData
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const checkPhoneNumberInDb = async (obj) => {
  try {
    const { data } = await client.post("/student/checkPhoneNumberInDb", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

// obj = {passportNo}
export const sendPassportVerification = async (obj) => {
  try {
    const { data } = await client.post(
      "/student/sendPassportVerification",
      obj
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

//  obj = { email, code }
export const verifyEmail = async (obj) => {
  try {
    const { data } = await client.post("/student/verifyEmail", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getStudents = async () => {
  try {
    const { data } = await client.get("/student/getStudents");
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const studentUpdate = (id, obj) => {
  return new Promise((resolve, reject) => {
    client
      .put(`/student/update/${id}`, obj)
      .then((response) => {
        resolve({ success: response.data.msg });
      })
      .catch((error) => {
        if (error.response?.data?.error) {
          reject({ error: error.response.data.error });
        } else {
          reject({ error: error.message || error });
        }
      });
  });
};

export const studentDelete = (id) => {
  return new Promise((resolve, reject) => {
    client
      .delete(`/student/delete/${id}`)
      .then((response) => {
        resolve({ success: response.data.msg });
      })
      .catch((error) => {
        if (error.response?.data?.error) {
          reject({ error: error.response.data.error });
        } else {
          reject({ error: error.message || error });
        }
      });
  });
};

export const deletePayment = async (studentId, paymentId) => {
  try {
    const { data } = await client.delete(
      `/student/deletePayment/${studentId}/${paymentId}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getRefundPaymentInfo = async (studentId) => {
  try {
    const { data } = await client.get(
      `/student/getRefundPaymentInfo/${studentId}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const updateRefundPayment = async (studentId, obj) => {
  try {
    console.log(studentId, obj);
    const { data } = await client.put(
      `/student/updateRefund/${studentId}`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const refundPayment = async (studentId, obj) => {
  try {
    const { data } = await client.post(
      `/student/insertRefund/${studentId}`,
      obj
    );
    console.log(obj, "obj");
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
