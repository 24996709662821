import client from "./client";

export const allStudentsInfoByAccounter = async () => {
  try {
    const { data } = await client.get(`/acc_agency/allStudentsInfoByAccounter`);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getBdms = async () => {
  try {
    const { data } = await client.get(`/acc_agency/getBdms`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getStudentFeeDetails = async () => {
  try {
    const { data } = await client.get(`/acc_agency/get-student-fees-info`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
