import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { Spin } from "antd";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { setPassword } from "../api/agency";
import { useParams } from "react-router-dom";
import { decryptRole, encryptRole } from "./EncryptionUtils";

function SetPassword() {
  const { encryptedUrl } = useParams();
  const [formValues, setFormValues] = useState({
    password: "",
    cpassword: "",
    code: encryptedUrl,
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (formValues.password === formValues.cpassword) {
        const data = await setPassword(formValues);
        if (data?.success) {
          toast.success(`Password updated successfully`);
          navigate("/");
        } else {
          toast.error(data.error);
        }
        setLoading(false);
      } else {
        toast.error("Password and Confirm Password should be same");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <Toaster position="top-center" reverseOrder={false} />
      <form
        onSubmit={handleSubmit}
        className="max-w-md w-4/5 mx-auto p-8 rounded-md shadow-lg bg-white bg-opacity-50 border"
      >
        <h1 className="text-2xl font-bold mb-4 text-center">Set Password</h1>

        <div className="mb-4">
          <label className="block text-black font-semibold">Password</label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={formValues.password}
              onChange={handleChange}
              className="w-full p-2 rounded-sm border"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-2 top-2"
            >
              {showPassword ? (
                <FaEyeSlash className="h-5 w-5 text-black" />
              ) : (
                <FaEye className="h-5 w-5 text-black" />
              )}
            </button>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-black font-semibold">
            Confirm Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="cpassword"
              placeholder="New Password"
              value={formValues.cpassword}
              onChange={handleChange}
              className="w-full p-2 rounded-sm border"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-2 top-2"
            >
              {showPassword ? (
                <FaEyeSlash className="h-5 w-5 text-black" />
              ) : (
                <FaEye className="h-5 w-5 text-black" />
              )}
            </button>
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:scale-105 transition duration-150"
          disabled={loading}
        >
          {loading ? (
            <div className="flex justify-center items-center">
              <Spin size="large" style={{ color: "white" }} />
            </div>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
}

export default SetPassword;
