import client from "./client";

// FOR SAVING THE TTCOPY
// (STUDENTID, FORMDATA(INDEX OF TTCOPY, TTCOPY FILE))
// To add new transaction !
export const updatePaymentStatus1 = (studentId, obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(
        `/collage/updatePaymentStatus1/${studentId}`,
        obj
      );
      resolve({ data, success: "Successfully saved the TT Copy" });
    } catch (error) {
      const { response } = error;
      if (response?.data) {
        reject(response.data);
      } else {
        reject({ error: error.message || error });
      }
    }
  });
};

// To Update the Transaction
export const updateTransaction = (studentId, obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(
        `/collage/updateTransaction/${studentId}`,
        obj
      );
      resolve({ data, success: "Successfully saved the TT Copy" });
    } catch (error) {
      const { response } = error;
      if (response?.data) {
        reject(response.data);
      } else {
        reject({ error: error.message || error });
      }
    }
  });
};
// export const addTransactionNo = async () => {
//   try {
//     const { data } = await client.get(`/acc_agency/allStudentsInfoByAccounter`);
//     return data.data;
//   } catch (error) {
//     const { response } = error;
//     if (response?.data) {
//       return response.data;
//     }
//     return { error: error.message || error };
//   }
// };

export const addTransactionNo = async (studentId, paymentId, obj) => {
  try {
    const { data } = await client.post(
      `/collage/addTransactionNo/${studentId}/${paymentId}`,
      obj
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getTransaction = async (studentId, type) => {
  try {
    const { data } = await client.get(
      `/collage/getTransaction/${studentId}/${type}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getPaymentInfo = async (studentId) => {
  try {
    const { data } = await client.get(`/collage/getPaymentInfo/${studentId}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const sendMail = async (obj) => {
  try {
    const { data } = await client.post("/student/sendMail", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getPDF = async (obj) => {
  try {
    const { data } = await client.post("/student/get-pdf", obj);

    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const sendPaymentMail = (studentId) => {
  return new Promise((resolve, reject) => {
    client
      .post(`/collage/sendPaymentMail/${studentId}`)
      .then((response) => {
        resolve({ success: response.data.msg });
      })
      .catch((error) => {
        const { response } = error;
        if (response?.data?.error) {
          reject({ error: response.data.error });
        } else {
          reject({ error: error.message || error });
        }
      });
  });
};
