import React, { useContext, useEffect, useState } from "react";
import { StudentContext } from "../context/StudentContext";

import logo from "../assets/logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { decryptRole } from "../Authentication/EncryptionUtils";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authToken = localStorage.getItem("authToken");

  const studentContext = useContext(StudentContext);
  const { getRole } = studentContext;

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("id");
    localStorage.removeItem("role");
    navigate("/");
  };

  return (
    <div className="flex items-center shadow-lg border-b-2 border-orange-300 selection:">
      <div className="lg:ml-5 md:ml-5 mt-2">
        <img src={logo} alt="logo" width={160} height={100} />
      </div>
      <div className=" flex ml-auto items-center justify-center">
        {authToken ? (
          <>
            {getRole() && getRole() === "agency" && (
              <Link
                to="form"
                className={`font-semibold text-black-500 text-center text-xl hover:border-b border-gray-300 ${
                  location.pathname === "/form"
                    ? "border-orange-500 border-b text-orange-500"
                    : "hover:border-orange-500 hover:text-orange-500"
                } py-1 px-3`}
              >
                Student Application
              </Link>
            )}
            {getRole() && getRole() === "agency" && (
              <Link
                to="agencyStudentTable"
                className={` font-semibold text-black-500 text-center text-xl hover:border-b border-gray-300 ${
                  location.pathname === "/agencyStudentTable"
                    ? "border-orange-500 border-b text-orange-500"
                    : "hover:border-orange-500 hover:text-orange-500"
                } py-1 px-3`}
              >
                Dashboard
              </Link>
            )}
            <div className=" mr-10">
              <button
                className="hover:border-b font-semibold text-xl border-gray-300 hover:border-orange-500 transtion duration-300 ease-in-out hover:text-orange-500 py-1"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </>
        ) : (
          <div className="flex  items-center ml-auto">
            <div className="my-auto ml-auto mr-10">
              <Link
                to="/"
                className={`hover:border-b text-xl border-gray-300 ${
                  location.pathname === "/"
                    ? "border-orange-500 text-orange-500"
                    : "hover:border-orange-500 hover:text-orange-500"
                } transtion duration-300 ease-in-out py-1`}
              >
                LOGIN
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
