const logo =
  "https://res.cloudinary.com/dqhqtw1uz/image/upload/v1706258643/wkzcqdrl3kc9jkyqxdts.png";

export function PaymentMail(inEditStudent, paymentLink) {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
 <html
   xmlns="http://www.w3.org/1999/xhtml"
   xmlns:v="urn:schemas-microsoft-com:vml"
   xmlns:o="urn:schemas-microsoft-com:office:office"
 >
   <head>
     <!--[if gte mso 9]>
       <xml>
         <o:OfficeDocumentSettings>
           <o:AllowPNG />
           <o:PixelsPerInch>96</o:PixelsPerInch>
         </o:OfficeDocumentSettings>
       </xml>
     <![endif]-->
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta name="viewport" content="width=device-width, initial-scale=1.0" />
     <meta name="x-apple-disable-message-reformatting" />
     <!--[if !mso]><!-->
     <meta http-equiv="X-UA-Compatible" content="IE=edge" />
     <!--<![endif]-->
     <title></title>
 
     <style type="text/css">
       @media only screen and (min-width: 520px) {
         .u-row {
           width: 500px !important;
         }
         .u-row .u-col {
           vertical-align: top;
         }
 
         .u-row .u-col-50 {
           width: 250px !important;
         }
 
         .u-row .u-col-100 {
           width: 500px !important;
         }
       }
 
       @media (max-width: 520px) {
         .u-row-container {
           max-width: 100% !important;
           padding-left: 0px !important;
           padding-right: 0px !important;
         }
         .u-row .u-col {
           min-width: 320px !important;
           max-width: 100% !important;
           display: block !important;
         }
         .u-row {
           width: 100% !important;
         }
         .u-col {
           width: 100% !important;
         }
         .u-col > div {
           margin: 0 auto;
         }
       }
       body {
         margin: 0;
         padding: 0;
       }
 
       table,
       tr,
       td {
         vertical-align: top;
         border-collapse: collapse;
       }
 
       p {
         margin: 0;
       }
 
       .ie-container table,
       .mso-container table {
         table-layout: fixed;
       }
 
       * {
         line-height: inherit;
       }
 
       a[x-apple-data-detectors="true"] {
         color: inherit !important;
         text-decoration: none !important;
       }
 
       table,
       td {
         color: #000000;
       }
       #u_body a {
         color: #ffffff;
         text-decoration: none;
       }
       @media (max-width: 480px) {
         #u_column_13 .v-col-border {
           border-top: 0px solid transparent !important;
           border-left: 0px solid transparent !important;
           border-right: 0px solid transparent !important;
           border-bottom: 0px solid transparent !important;
         }
         #u_content_image_5 .v-container-padding-padding {
           padding: 0px !important;
         }
         #u_content_image_5 .v-src-width {
           width: auto !important;
         }
         #u_content_image_5 .v-src-max-width {
           max-width: 30% !important;
         }
         #u_column_5 .v-col-border {
           border-top: 0px solid transparent !important;
           border-left: 0px solid transparent !important;
           border-right: 0px solid transparent !important;
           border-bottom: 0px solid transparent !important;
         }
         #u_content_text_10 .v-container-padding-padding {
           padding: 12px 15px 22px 25px !important;
         }
         #u_column_17 .v-col-padding {
           padding: 0px 0px 0px 25px !important;
         }
         #u_content_text_16 .v-container-padding-padding {
           padding: 10px 10px 0px !important;
         }
         #u_column_18 .v-col-padding {
           padding: 0px 0px 0px 25px !important;
         }
         #u_column_23 .v-col-padding {
           padding: 0px 0px 0px 25px !important;
         }
         #u_column_24 .v-col-padding {
           padding: 0px 0px 20px 25px !important;
         }
       }
     </style>
   </head>
 
   <body
     className="clean-body u_body"
     style="
       margin: 0;
       padding: 0;
       -webkit-text-size-adjust: 100%;
       background-color: #e6e6e6;
       color: #000000;
     "
   >
     <!--[if IE]><div className="ie-container"><![endif]-->
     <!--[if mso]><div className="mso-container"><![endif]-->
     <table
       id="u_body"
       style="
         border-collapse: collapse;
         table-layout: fixed;
         border-spacing: 0;
         mso-table-lspace: 0pt;
         mso-table-rspace: 0pt;
         vertical-align: top;
         min-width: 320px;
         margin: 0 auto;
         background-color: #e6e6e6;
         width: 100%;
       "
       cellpadding="0"
       cellspacing="0"
     >
       <tbody>
         <tr style="vertical-align: top">
           <td
             style="
               word-break: break-word;
               border-collapse: collapse !important;
               vertical-align: top;
             "
           >
             <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #e6e6e6;"><![endif]-->
 
             <div
               className="u-row-container"
               style="padding: 0px; background-color: transparent"
             >
               <div
                 className="u-row"
                 style="
                   margin: 0 auto;
                   min-width: 320px;
                   max-width: 500px;
                   overflow-wrap: break-word;
                   word-wrap: break-word;
                   word-break: break-word;
                   background-color: transparent;
                 "
               >
                 <div
                   style="
                     border-collapse: collapse;
                     display: table;
                     width: 100%;
                     height: 100%;
                     background-color: transparent;
                   "
                 >
                   <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
 
                   <!--[if (mso)|(IE)]><td align="center" width="500" className="v-col-padding v-col-border" style="background-color: #ffffff;width: 500px;padding: 0px;border-top: 12px solid #e6e6e6;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                   <div
                     id="u_column_13"
                     className="u-col u-col-100"
                     style="
                       max-width: 320px;
                       min-width: 500px;
                       display: table-cell;
                       vertical-align: top;
                     "
                   >
                     <div
                       style="
                         background-color: #ffffff;
                         height: 100%;
                         width: 100% !important;
                         border-radius: 0px;
                         -webkit-border-radius: 0px;
                         -moz-border-radius: 0px;
                       "
                     >
                       <!--[if (!mso)&(!IE)]><!--><div
                         className="v-col-padding v-col-border"
                         style="
                           box-sizing: border-box;
                           height: 100%;
                           padding: 0px;
                           border-top: 12px solid #e6e6e6;
                           border-left: 0px solid transparent;
                           border-right: 0px solid transparent;
                           border-bottom: 0px solid transparent;
                           border-radius: 0px;
                           -webkit-border-radius: 0px;
                           -moz-border-radius: 0px;
                         "
                       ><!--<![endif]-->
                         <table
                           id="u_content_image_5"
                           style="font-family: arial, helvetica, sans-serif"
                           role="presentation"
                           cellpadding="0"
                           cellspacing="0"
                           width="100%"
                           border="0"
                         >
                           <tbody>
                             <tr>
                               <td
                                 className="v-container-padding-padding"
                                 style="
                                   overflow-wrap: break-word;
                                   word-break: break-word;
                                   padding: 10px 0px 0px;
                                   font-family: arial, helvetica, sans-serif;
                                 "
                                 align="left"
                               >
                                 <table
                                   width="100%"
                                   cellpadding="0"
                                   cellspacing="0"
                                   border="0"
                                 >
                                   <tr>
                                     <td
                                       style="
                                         padding-right: 0px;
                                         padding-left: 0px;
                                       "
                                       align="center"
                                     >
                                       <img
                                         align="center"
                                         border="0"
                                         src="${logo}"
                                         alt=""
                                         title=""
                                         style="
                                           outline: none;
                                           text-decoration: none;
                                           -ms-interpolation-mode: bicubic;
                                           clear: both;
                                           display: inline-block !important;
                                           border: none;
                                           height: auto;
                                           float: none;
                                           width: 20%;
                                           max-width: 100px;
                                         "
                                         width="60"
                                         className="v-src-width v-src-max-width"
                                       />
                                     </td>
                                   </tr>
                                 </table>
                               </td>
                             </tr>
                           </tbody>
                         </table>
 
                         <!--[if (!mso)&(!IE)]><!-->
                       </div>
                       <!--<![endif]-->
                     </div>
                   </div>
                   <!--[if (mso)|(IE)]></td><![endif]-->
                   <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                 </div>
               </div>
             </div>
 
             <div
               className="u-row-container"
               style="padding: 0px; background-color: transparent"
             >
               <div
                 className="u-row"
                 style="
                   margin: 0 auto;
                   min-width: 320px;
                   max-width: 500px;
                   overflow-wrap: break-word;
                   word-wrap: break-word;
                   word-break: break-word;
                   background-color: transparent;
                 "
               >
                 <div
                   style="
                     border-collapse: collapse;
                     display: table;
                     width: 100%;
                     height: 100%;
                     background-color: transparent;
                   "
                 >
                   <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
 
                   <!--[if (mso)|(IE)]><td align="center" width="500" className="v-col-padding v-col-border" style="background-color: #ffffff;width: 500px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                   <div
                     className="u-col u-col-100"
                     style="
                       max-width: 320px;
                       min-width: 500px;
                       display: table-cell;
                       vertical-align: top;
                     "
                   >
                     <div
                       style="
                         background-color: #ffffff;
                         height: 100%;
                         width: 100% !important;
                         border-radius: 0px;
                         -webkit-border-radius: 0px;
                         -moz-border-radius: 0px;
                       "
                     >
                       <!--[if (!mso)&(!IE)]><!--><div
                         className="v-col-padding v-col-border"
                         style="
                           box-sizing: border-box;
                           height: 100%;
                           padding: 0px;
                           border-top: 0px solid transparent;
                           border-left: 0px solid transparent;
                           border-right: 0px solid transparent;
                           border-bottom: 0px solid transparent;
                           border-radius: 0px;
                           -webkit-border-radius: 0px;
                           -moz-border-radius: 0px;
                         "
                       ><!--<![endif]-->
                         <table
                           style="font-family: arial, helvetica, sans-serif"
                           role="presentation"
                           cellpadding="0"
                           cellspacing="0"
                           width="100%"
                           border="0"
                         >
                           <tbody>
                             <tr>
                               <td
                                 className="v-container-padding-padding"
                                 style="
                                   overflow-wrap: break-word;
                                   word-break: break-word;
                                   padding: 2px;
                                   font-family: arial, helvetica, sans-serif;
                                 "
                                 align="left"
                               >
                                 <!--[if mso]><table width="100%"><tr><td><![endif]-->
                                 <h2
                                   style="
                                     margin: 0px;
                                     color: #232323;
                                     line-height: 190%;
                                     text-align: center;
                                     word-wrap: break-word;
                                     font-family: arial, helvetica, sans-serif;
                                     font-size: 6px;
                                     font-weight: 400;
                                   "
                                 >
                                   <h3 style="text-align: center">
                                     Lyon Institute For Geostrategy And
                                     Multicultural Relations
                                   </h3>
                                 </h2>
                                 <!--[if mso]></td></tr></table><![endif]-->
                               </td>
                             </tr>
                           </tbody>
                         </table>
 
                         <!--[if (!mso)&(!IE)]><!-->
                       </div>
                       <!--<![endif]-->
                     </div>
                   </div>
                   <!--[if (mso)|(IE)]></td><![endif]-->
                   <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                 </div>
               </div>
             </div>
 
             <div
               className="u-row-container"
               style="padding: 0px; background-color: transparent"
             >
               <div
                 className="u-row"
                 style="
                   margin: 0 auto;
                   min-width: 320px;
                   max-width: 500px;
                   overflow-wrap: break-word;
                   word-wrap: break-word;
                   word-break: break-word;
                   background-color: transparent;
                 "
               >
                 <div
                   style="
                     border-collapse: collapse;
                     display: table;
                     width: 100%;
                     height: 100%;
                     background-color: transparent;
                   "
                 >
                   <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
 
                   <!--[if (mso)|(IE)]><td align="center" width="400" className="v-col-padding v-col-border" style="background-color: #e6e6e6;width: 400px;padding: 0px;border-top: 10px solid #ffffff;border-left: 50px solid #ffffff;border-right: 50px solid #ffffff;border-bottom: 19px solid #ffffff;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                   <div
                     id="u_column_5"
                     className="u-col u-col-100"
                     style="
                       max-width: 320px;
                       min-width: 500px;
                       display: table-cell;
                       vertical-align: top;
                     "
                   >
                     <div
                       style="
                         background-color: #e6e6e6;
                         height: 100%;
                         width: 100% !important;
                         border-radius: 0px;
                         -webkit-border-radius: 0px;
                         -moz-border-radius: 0px;
                       "
                     >
                       <!--[if (!mso)&(!IE)]><!--><div
                         className="v-col-padding v-col-border"
                         style="
                           box-sizing: border-box;
                           height: 100%;
                           padding: 0px;
                           border-top: 10px solid #ffffff;
                           border-left: 50px solid #ffffff;
                           border-right: 50px solid #ffffff;
                           border-bottom: 19px solid #ffffff;
                           border-radius: 0px;
                           -webkit-border-radius: 0px;
                           -moz-border-radius: 0px;
                         "
                       ><!--<![endif]-->
                         <table
                           id="u_content_text_10"
                           style="font-family: arial, helvetica, sans-serif"
                           role="presentation"
                           cellpadding="0"
                           cellspacing="0"
                           width="100%"
                           border="0"
                         >
                           <tbody>
                             <tr>
                               <td
                                 className="v-container-padding-padding"
                                 style="
                                   overflow-wrap: break-word;
                                   word-break: break-word;
                                   padding: 21px;
                                   font-family: arial, helvetica, sans-serif;
                                 "
                                 align="left"
                               >
                                 <div
                                   style="
                                     font-size: 14px;
                                     line-height: 140%;
                                     text-align: left;
                                     word-wrap: break-word;
                                   "
                                 >
                                   <p style="line-height: 140%">
                                     <span
                                       data-metadata="&lt;!--(figmeta)eyJmaWxlS2V5IjoialozYzlVTnFJVjFOaDJvYXYxcXFUQyIsInBhc3RlSUQiOjE1OTMxMDIyODAsImRhdGFUeXBlIjoic2NlbmUifQo=(/figmeta)--&gt;"
                                       style="line-height: 19.6px"
                                       ><span style="line-height: 19.6px"
                                         > Dear ${inEditStudent.lastName.toUpperCase()} ${
    inEditStudent.firstName
  }</span
                                       ></span
                                     >
                                   </p>
                                   <p style="line-height: 140%"> </p>
                                   <div>
                                     <div>
                                     Please find the attached payment details and bank information for the registration fee to proceed
      with your enrollment. After completing the transaction, kindly forward the payment receipt to
      your counselor for our records.
                                     </div>
                                     <div>
                                     Kindly make the payment with the given link below </br> 
                                     <a style="color:blue" href="${paymentLink}"><strong>${paymentLink}</strong></a>
                                     </div>
                                     
                                   </div>
                                   <p style="line-height: 140%"> </p>
                                   <div>
                                     <div>Thank you,</div>
                                     <div>Finance Team</div>
                                     <div>
                                       Lyon Institute for Geostrategy &amp;
                                       Multicultural Relations
                                     </div>
                                   </div>
                                 </div>
                               </td>
                             </tr>
                           </tbody>
                         </table>
 
                         <!--[if (!mso)&(!IE)]><!-->
                       </div>
                       <!--<![endif]-->
                     </div>
                   </div>
                   <!--[if (mso)|(IE)]></td><![endif]-->
                   <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                 </div>
               </div>
             </div>
 
             <div
               className="u-row-container"
               style="padding: 0px; background-color: transparent"
             >
               <div
                 className="u-row"
                 style="
                   margin: 0 auto;
                   min-width: 320px;
                   max-width: 500px;
                   overflow-wrap: break-word;
                   word-wrap: break-word;
                   word-break: break-word;
                   background-color: transparent;
                 "
               >
                 <div
                   style="
                     border-collapse: collapse;
                     display: table;
                     width: 100%;
                     height: 100%;
                     background-color: transparent;
                   "
                 >
                   <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->
 
                   <!--[if (mso)|(IE)]><td align="center" width="250" className="v-col-padding v-col-border" style="background-color: #232323;width: 250px;padding: 20px 0px 0px 25px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                   <div
                     id="u_column_17"
                     className="u-col u-col-50"
                     style="
                       max-width: 320px;
                       min-width: 250px;
                       display: table-cell;
                       vertical-align: top;
                     "
                   >
                     <div
                       style="
                         background-color: #232323;
                         height: 100%;
                         width: 100% !important;
                         border-radius: 0px;
                         -webkit-border-radius: 0px;
                         -moz-border-radius: 0px;
                       "
                     >
                       <!--[if (!mso)&(!IE)]><!--><div
                         className="v-col-padding v-col-border"
                         style="
                           box-sizing: border-box;
                           height: 100%;
                           padding: 20px 0px 0px 25px;
                           border-top: 0px solid transparent;
                           border-left: 0px solid transparent;
                           border-right: 0px solid transparent;
                           border-bottom: 0px solid transparent;
                           border-radius: 0px;
                           -webkit-border-radius: 0px;
                           -moz-border-radius: 0px;
                         "
                       ><!--<![endif]-->
                       <table
                       id="u_content_text_16"
                       style="font-family: arial, helvetica, sans-serif"
                       role="presentation"
                       cellpadding="0"
                       cellspacing="0"
                       width="100%"
                       border="0"
                     >
                       <tbody>
                         <tr>
                           <td
                             className="v-container-padding-padding"
                             style="
                               overflow-wrap: break-word;
                               word-break: break-word;
                               padding: 10px;
                               font-family: arial, helvetica, sans-serif;
                             "
                             align="left"
                           >
                             <div
                               style="
                                 font-size: 14px;
                                 color: #ffffff;
                                 line-height: 140%;
                                 text-align: left;
                                 word-wrap: break-word;
                               "
                             >
                               <p style="line-height: 140%">
                                 <span
                                   style="
                                     color: #95a5a6;
                                     line-height: 19.6px;
                                   "
                                   >PHONE  </span>:   
                               </p>
                               <p>
                               <span
                                   style="
                                     color: #ffffff;
                                     line-height: 19.6px;
                                   "
                                   ><a
                                     rel="noopener"
                                     href="tel:+3320426837301"
                                     target="_blank"
                                     style="color: #ffffff"
                                     >FR +33 426837301
                                     </a>
                                  </span>
                                  <br/>
                                 <span
                                   style="
                                     line-height: 19.6px;
                                     color: #ffffff;
                                   "
                                   ><span style="line-height: 19.6px"
                                       ><a
                                         rel="noopener"
                                         href="tel:+917969459250"
                                         target="_blank"
                                         style="color: #ffffff"
                                         ><span style="line-height: 19.6px"
                                           >IN +917969459250</span
                                         ></a
                                       >
                                     </span>
                                   </span>

                                 
                               </p>
                               <p style="line-height: 140%"></p>
                             </div>
                           </td>
                         </tr>
                       </tbody>
                     </table>

                     <!--[if (!mso)&(!IE)]><!-->
                   </div>
                   <!--<![endif]-->
                 </div>
               </div>
               <!--[if (mso)|(IE)]></td><![endif]-->
               <!--[if (mso)|(IE)]><td align="center" width="250" className="v-col-padding v-col-border" style="background-color: #232323;width: 250px;padding: 20px 0px 0px 2px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
               <div
                 id="u_column_18"
                 className="u-col u-col-50"
                 style="
                   max-width: 320px;
                   min-width: 250px;
                   display: table-cell;
                   vertical-align: top;
                 "
               >
                 <div
                   style="
                     background-color: #232323;
                     height: 100%;
                     width: 100% !important;
                     border-radius: 0px;
                     -webkit-border-radius: 0px;
                     -moz-border-radius: 0px;
                   "
                 >
                   <!--[if (!mso)&(!IE)]><!--><div
                     className="v-col-padding v-col-border"
                     style="
                       box-sizing: border-box;
                       height: 100%;
                       padding: 20px 0px 0px 2px;
                       border-top: 0px solid transparent;
                       border-left: 0px solid transparent;
                       border-right: 0px solid transparent;
                       border-bottom: 0px solid transparent;
                       border-radius: 0px;
                       -webkit-border-radius: 0px;
                       -moz-border-radius: 0px;
                     "
                   ><!--<![endif]-->
                     <table
                       style="font-family: arial, helvetica, sans-serif"
                       role="presentation"
                       cellpadding="0"
                       cellspacing="0"
                       width="100%"
                       border="0"
                     >
                       <tbody>
                         <tr>
                           <td
                             className="v-container-padding-padding"
                             style="
                               overflow-wrap: break-word;
                               word-break: break-word;
                               padding: 10px;
                               font-family: arial, helvetica, sans-serif;
                             "
                             align="left"
                           >
                             <div
                               style="
                                 font-size: 14px;
                                 color: #ffffff;
                                 line-height: 140%;
                                 text-align: left;
                                 word-wrap: break-word;
                               "
                             >
                               <p style="line-height: 140%">
                                 <span
                                   style="
                                     color: #95a5a6;
                                     line-height: 19.6px;
                                   "
                                   >ADDRESS </span
                                 >:<br /><strong><u>France</u></strong> : Tour du Crédit Lyonnais,<br />129
                                 Rue Servient, 69003 Lyon, France.
                                 </span>
                               </p>
                             </div>
                           </td>
                         </tr>
                       </tbody>
                     </table>

                     <!--[if (!mso)&(!IE)]><!-->
                   </div>
                   <!--<![endif]-->
                 </div>
               </div>
               <!--[if (mso)|(IE)]></td><![endif]-->
               <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
             </div>
           </div>
         </div>

         <div
           className="u-row-container"
           style="padding: 0px; background-color: transparent"
         >
           <div
             className="u-row"
             style="
               margin: 0 auto;
               min-width: 320px;
               max-width: 500px;
               overflow-wrap: break-word;
               word-wrap: break-word;
               word-break: break-word;
               background-color: transparent;
             "
           >
             <div
               style="
                 border-collapse: collapse;
                 display: table;
                 width: 100%;
                 height: 100%;
                 background-color: transparent;
               "
             >
               <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px;"><tr style="background-color: transparent;"><![endif]-->

               <!--[if (mso)|(IE)]><td align="center" width="250" className="v-col-padding v-col-border" style="background-color: #232323;width: 250px;padding: 0px 0px 23px 25px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
               <div
                 id="u_column_23"
                 className="u-col u-col-50"
                 style="
                   max-width: 320px;
                   min-width: 250px;
                   display: table-cell;
                   vertical-align: top;
                 "
               >
                 <div
                   style="
                     background-color: #232323;
                     height: 100%;
                     width: 100% !important;
                     border-radius: 0px;
                     -webkit-border-radius: 0px;
                     -moz-border-radius: 0px;
                   "
                 >
                   <!--[if (!mso)&(!IE)]><!--><div
                     className="v-col-padding v-col-border"
                     style="
                       box-sizing: border-box;
                       height: 100%;
                       padding: 0px 0px 23px 25px;
                       border-top: 0px solid transparent;
                       border-left: 0px solid transparent;
                       border-right: 0px solid transparent;
                       border-bottom: 0px solid transparent;
                       border-radius: 0px;
                       -webkit-border-radius: 0px;
                       -moz-border-radius: 0px;
                     "
                   ><!--<![endif]-->
                     <table
                       style="font-family: arial, helvetica, sans-serif"
                       role="presentation"
                       cellpadding="0"
                       cellspacing="0"
                       width="100%"
                       border="0"
                     >
                       <tbody>
                         <tr>
                           <td
                             className="v-container-padding-padding"
                             style="
                               overflow-wrap: break-word;
                               word-break: break-word;
                               padding: 10px;
                               font-family: arial, helvetica, sans-serif;
                             "
                             align="left"
                           >
                             <div
                               style="
                                 font-size: 14px;
                                 line-height: 140%;
                                 text-align: left;
                                 word-wrap: break-word;
                               "
                             >
                               <p style="line-height: 140%">
                                 <span
                                   style="
                                     color: #7e8c8d;
                                     line-height: 19.6px;
                                   "
                                   >EMAIL : </span
                                 >:<br /><span
                                   style="
                                     color: #ffffff;
                                     line-height: 19.6px;
                                   "
                                   ><a
                                     rel="noopener"
                                     href="mailto:info@ligmr.fr"
                                     target="_blank"
                                     style="color: #ffffff"
                                     >info@ligmr.fr</a
                                   ></span
                                 >
                               </p>  
                             </div>
                           </td>
                         </tr>
                       </tbody>
                     </table>

                     <!--[if (!mso)&(!IE)]><!-->
                   </div>
                   <!--<![endif]-->
                 </div>
               </div>
               <!--[if (mso)|(IE)]></td><![endif]-->
               <!--[if (mso)|(IE)]><td align="center" width="250" className="v-col-padding v-col-border" style="background-color: #232323;width: 250px;padding: 2px 2px 23px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
               <div
                 id="u_column_24"
                 className="u-col u-col-50"
                 style="
                   max-width: 320px;
                   min-width: 250px;
                   display: table-cell;
                   vertical-align: top;
                 "
               >
                 <div
                   style="
                     background-color: #232323;
                     height: 100%;
                     width: 100% !important;
                     border-radius: 0px;
                     -webkit-border-radius: 0px;
                     -moz-border-radius: 0px;
                   "
                 >
                   <!--[if (!mso)&(!IE)]><!--><div
                     className="v-col-padding v-col-border"
                     style="
                       box-sizing: border-box;
                       height: 100%;
                       padding: 2px 2px 23px;
                       border-top: 0px solid transparent;
                       border-left: 0px solid transparent;
                       border-right: 0px solid transparent;
                       border-bottom: 0px solid transparent;
                       border-radius: 0px;
                       -webkit-border-radius: 0px;
                       -moz-border-radius: 0px;
                     "
                   ><!--<![endif]-->
                     <table
                       style="font-family: arial, helvetica, sans-serif"
                       role="presentation"
                       cellpadding="0"
                       cellspacing="0"
                       width="100%"
                       border="0"
                     >
                       <tbody>
                         <tr>
                           <td
                             className="v-container-padding-padding"
                             style="
                               overflow-wrap: break-word;
                               word-break: break-word;
                               padding: 10px;
                               font-family: arial, helvetica, sans-serif;
                             "
                             align="left"
                           >
                             <div
                               style="
                                 font-size: 14px;
                                 line-height: 140%;
                                 text-align: left;
                                 word-wrap: break-word;
                               "
                             >
                               <p style="line-height: 140%">
                                 <span
                                   style="
                                     color: #ced4d9;
                                     line-height: 19.6px;
                                   "
                                   ><span
                                     style="
                                       color: #7e8c8d;
                                       line-height: 19.6px;
                                     "
                                     >WEBSITE </span
                                   >:</span
                                 >:<br /><span
                                   style="
                                     color: #ffffff;
                                     line-height: 19.6px;
                                   "
                                   ><a
                                     rel="noopener noreferrer"
                                     href="https://www.ligmr.fr/"
                                     target="_blank"
                                     style="color: #ffffff"
                                     >www.ligmr.fr</a
                                   ></span
                                 >
                               </p>
                             </div>
                           </td>
                         </tr>
                       </tbody>
                     </table>

                     <!--[if (!mso)&(!IE)]><!-->
                   </div>
                   <!--<![endif]-->
                 </div>
               </div>
               <!--[if (mso)|(IE)]></td><![endif]-->
               <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
             </div>
           </div>
         </div>

         <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
       </td>
     </tr>
   </tbody>
 </table>
 <!--[if mso]></div><![endif]-->
 <!--[if IE]></div><![endif]-->
</body>
</html>
 `;
}
