import client from "./client";

export const acc_agency_signin = async (obj) => {
  try {
    const { data } = await client.post("/acc_agency/signin", obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
